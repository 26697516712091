import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

import Header from './components/Header';
import Footer from './components/Footer';
import Section1 from './components/Section1';
import Section1b from './components/Section1b';
import Section2 from './components/Section2';
import Section3 from './components/Section3';
import Section4 from './components/Section4';
import Section5 from './components/Section5';
import OurStory from './components/OurStory';
import About from './components/About';
import Blog from './components/Blog';
import Contact from './components/Contact';
import BEntry1 from './components/BEntry1';
import BEntry2 from './components/BEntry2';
import BEntry3 from './components/BEntry3';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        {/* <div className="test">Jeremy Tong</div> */}
        <Header />
        <div className="App-main">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Section1 />
                  <Section1b />
                  <Section2 />
                  <Section3 />
                  <Section4 />
                  <Section5 />
                </>
              }
            />
            <Route path="/our-story" element={<OurStory />} />
            <Route path="/about" element={<About />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/blog/tug-of-war" element={<BEntry1 />} />{' '}
            {/* New Route */}
            <Route path="/blog/tug-of-war2" element={<BEntry2 />} />
            <Route path="/blog/tug-of-war2" element={<BEntry2 />} />
            <Route path="/blog/client-first" element={<BEntry3 />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
