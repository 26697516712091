import '../css/Section2_BEntry3.css';

function Section2_BEntry3() {


  return (
    <div className="Section2-BEntry3-container">
      <div className="Section2-BEntry3-content">
        <div className="Section2-BEntry3-left">
          <h1 className="Section2-BEntry3-heading1">Introduction</h1>
          <p>
          When managing construction projects, it’s easy to focus on the bigger 
          picture—timelines, budgets, and technical milestones. However, when 
          we have a big project outlined in our minds, the significance of 
          small interactions with clients can be overlooked.  Often these seemingly 
          trivial interactions can make a mountain of difference in the client’s mind.  
          Here are some examples from the AddWork’s team’s real-life interactions to 
          illustrate the point:
          </p>
          <h2 className="Section2-BEntry3-heading2">Scenario 1: Insulation Cleanup</h2>
          <p>
          The demo crew was working overtime on a Saturday and removed a sheetrock ceiling 
          filled with blown-in insulation. As the insulation dropped, it created a mess, 
          which the crew swept into a pile in the middle of the floor. It looked like a 
          snowy day in the middle of the home-build.  Instead of fully cleaning up, they 
          planned to return Monday to finish the cleanup with vacuums and make the site spotless.
          </p>
          <p>
          However, the client, living on the property in an accessory dwelling unit (ADU), 
          noticed the mess over the weekend. They emailed with concerns about insulation debris 
          being everywhere: inside the house, outside in planters.  The client went out of the 
          way to stress the fact that the insulation was blowing around outside, almost as though 
          it was likely to choke a squirrel or, who knows, somehow suffocate a sea turtle.  
          It was urgent!  To the crew, this was a temporary inconvenience—something easily fixed 
          on Monday. But to the client, it was a major issue affecting their living space.
          </p>
          <h3 className="Section2-BEntry3-heading3">Scenario 2: Attic Access</h3>
        </div>
        <div className="Section2-BEntry3-right">

            <p>
            During a walkthrough, the client casually requested an attic access in the master 
            bathroom closet. The request was noted, along with other framing adjustments, to 
            be addressed before insulation and drywall. A week later, the client followed up 
            via email, asking if the attic access had been missed - they had walked the site 
            and saw that nothing had been done.  Clearly, their request had been ignored! 
            Although it was already on the to-do list, the client’s email expressed the feeling 
            on their part that they had been totally ignored.
            </p>
            <p>
            From the contractor’s perspective, the attic access was a minor detail in the grand 
            scheme of the project, with more pressing tasks like plumbing and HVAC preparation 
            underway. However, to the client, it was top of mind because they had specifically 
            requested it. Every time they visited the site, they noticed it wasn’t done, leading 
            to potential doubts about the project’s organization and attentiveness.
            </p>
            <h4 className="Section2-BEntry3-heading4">Thoughts</h4>
            <p>
            Both scenarios highlight a common misconception in construction: clients are right 
            even when they are wrong.  In these examples, the client is frankly wrong to care about 
            these minor details because they don’t matter in the context of the big picture.  The 
            client caring about insulation not being vacuumed up the same day, or a tiny frame job 
            not being done immediately, is actually working against their end goal of a beautiful 
            finished project.  But here’s the point: while contractors are focused on the big picture, 
            clients often judge the project by the small details they can see and understand.
            </p>
            <p>
            Ultimately, building trust and showing that you prioritize the client’s needs—even 
            with small gestures—can create a stronger, more positive relationship. These seemingly 
            minor actions are often what clients remember most.
            </p>
        </div>
      </div>

    </div>
  );
}

export default Section2_BEntry3;
