import React from 'react';
import Header from './Header';
import Section5 from './Section5';
import Blog3 from './Section1_BEntry3'; // Updated import
import Section2_BEntry3 from './Section2_BEntry3'; // Updated import
// import Section3_BEntry3 from './Section3_BEntry3';
import '../css/BEntry3.css'; // Updated CSS file

function BEntry3() {
  return (
    <div className="B3-page">
      <Header />
      <div className="B3-main">
        <Blog3 /> {/* Rendering Blog3 */}
        <Section2_BEntry3 />
        {/* <Section3_BEntry3 /> */}
        <Section5 />
      </div>
    </div>
  );
}

export default BEntry3;
