import React, { useState } from 'react';
import '../css/Section2_BEntry2.css';

function Section2_BEntry2() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState('');

  const openModal = (imageSrc) => {
    setModalImageSrc(imageSrc);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalImageSrc('');
  };

  return (
    <div className="Section2-BEntry2-container">
      <div className="Section2-BEntry2-content">
        <div className="Section2-BEntry2-left">

            <h1 className="Section2-BEntry2-heading1">Inflation in the Crosshairs</h1>
            <img
              src={`${process.env.PUBLIC_URL}/images/b2-hold.png`}
              alt="Tug of War Intro"
              className="BEntry2-image"
              onClick={() => openModal(`${process.env.PUBLIC_URL}/images/b2-hold.png`)}
            />
            <p>
            We’ve established that high inflation is bad. When inflation stays high for long enough, it doesn’t just cause discomfort—it can snowball into something much worse. And as we’ve discussed, the Federal Reserve really only has one main tool to fight inflation: raising interest rates.</p>
            <p>
            But why does raising interest rates work? It does a few key things. First, it makes borrowing more expensive, which reduces the amount of money available to all of us. For example, houses may stay the same price, but the mortgages cost more because of the higher interest rates. The same is true for car loans, credit cards, and other forms of debt. Since it costs more to borrow, people spend less. And when fewer people are buying things, prices eventually start to drop.</p>
            <p>
            But let’s be real—higher interest rates aren’t fun for anyone. So, are there other options? Unfortunately, not really. The only other “option” is to pretend inflation isn’t a problem and do nothing—or worse, lower interest rates while inflation is high. But this only makes the situation spiral further, and here’s why.</p>
            <p>
            magine this: you’re worried the car you want will double in price next year, and you’re not expecting a raise. But interest rates just dropped, making financing cheaper. Do you wait, or head to the dealership now? Most of us would buy now, which makes sense when prices are climbing—people tend to spend sooner to avoid paying more later. Now, if your neighbors are thinking the same, everyone starts buying, and demand soars. This is how inflation accelerates, like a snowball gaining momentum down a hill.</p>

            <p>
            When interest rates drop, it becomes cheaper to borrow, making it easier to finance a car, refinance your home, or buy that new couch at 0% interest. At first, it seems great. But with lower rates, people are encouraged to spend more, which, in turn, drives prices up even faster.
            </p>
            <p>
            In short, managing inflation is a balancing act. The Fed can raise rates to slow things down, but that comes with its own challenges. Lowering rates during high inflation might feel good temporarily, but it only fuels the fire.</p>            

          </div>
          <div className="Section2-BEntry2-right">
            <h2 className="Section2-Bentry2-heading2">Everyone! Start Pulling!</h2>
            <p>
            As we’ve discussed in previous posts, the U.S. deficit is no longer just a talking point—it’s becoming a serious, “we really have to pay this off” genuinely scary problem. This isn’t about the usual debt ceiling debates; it’s about how the government can realistically meet its obligations pretty much right now or really see the debt spiral into zero options left but default. At the same time, while inflation seems to have cooled off, any unexpected uptick could destabilize the global economy and recent numbers have been suckered into complacency by massive reductions in energy costs since highs in 2022.</p>
            <p>
            In an ideal scenario, the Federal Reserve could reduce interest rates, and inflation would turn out to be nothing more than a brief blip. In fact, the Fed has already cut rates by 50 basis points (bps)—a modest 0.5% reduction. According to most, things “seem” stable.  However, that 50 bps cut is not enough to significantly ease the burden of financing U.S. government debt. To make a real difference, we’d need cuts in the range of 200 to 400 bps (or 2-4%).</p>

            <p>
            But here’s the catch: even with that modest 50 bps cut, and most “experts” suggesting all is well, the global economy feels fragile. While some are starting to believe that the U.S. economy is stabilizing, this optimism may be premature. As of now, the stock market is near all-time highs, and articles published in early October 2024 suggest that a soft landing is on the horizon. Economists are pointing to stable employment levels and inflation nearing target levels.  Things are not as shrug-it-off fine as we are being told.</p>
            <p>
            There are two key reasons to question the optimism. First, market jumpiness is exceptionally high. Jumpiness suggests that little things have outsized reaction even if overall volatility seems to be lower.  In early August, after months of steady gains, the S&P 500 sold off 8% in just three days. The catalyst? The economy added 114,000 jobs in July, but expectations were higher. Historically, this is not a particularly large shortfall. However, when the stock market is jittery, it’s often a signal that something deeper is going on. The fact that such a small miss in job data triggered panic suggests meaningful underlying fragility. A soft landing may feel inevitable—until it’s not, like a plane hitting turbulence.  Sometimes, things feel off.  As builders and laborers, we believe instinct matters.</p>
            <p>
            Second, let’s talk about jobs data, which seems to be the key economic indicator right now. The July report didn’t just show a miss in new jobs; it also revised downward job growth from previous months by 818,000 jobs. That’s a significant revision and maybe that adds context to why the market reacted so sharply to the 114,000 figure—it wasn’t just the miss, but the broader picture of declining momentum.</p>
            <p>
            Finally, the tug-of-war between managing the deficit and controlling inflation is becoming more intense, not less. Another reason why markets are so touchy, or maybe the real reason? Because this tug-of-war between inflation and debt is real. We have little room left to maneuver. On one hand, we must lower interest rates to make servicing the government’s debt affordable. On the other hand, any signs of economic instability—like a single jobs report—are enough to trigger mini-panics. Perhaps things aren’t as stable as we’re being led to believe.</p>
            <p>
            Here’s the crux of the issue: the only real way to reduce the U.S. government’s debt burden is to lower borrowing costs, which requires lower interest rates. But the only way to keep inflation in check is to raise interest rates, which increases the cost of borrowing. It’s a classic catch-22: what helps solve the deficit makes inflation worse, and what controls inflation makes managing the deficit harder.</p>
            <p>
            As a key sector in the U.S. economy, construction needs to ask: what comes next? What should we base our actions on? The question we all face is this:</p>
            <p>
            Can we find a way out this time?</p>
        </div>
      </div>

      {isModalOpen && (
        <div className="image-overlay" onClick={closeModal}>
          <span className="close-overlay">&times;</span>
          <img src={modalImageSrc} alt="Expanded" className="expanded-image" />
        </div>
      )}
    </div>
  );
}

export default Section2_BEntry2;