import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Section1_BEntry3.css'; // Updated CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';

function Blog3() {
  return (
    <div className="App-content-Blogsection3">
      <header className="Section1-headerb3">
        <nav className="App-navb3">
          <div className="App-logob3">
            <Link to="/">
              <img
                src={`${process.env.PUBLIC_URL}/images/AddWrk_logo_red.png`}
                alt="ADDWRK Logo"
              />
            </Link>
          </div>
          <ul className="App-menub3">
            <li><Link to="/our-story">OUR STORY</Link></li>
            <li><Link to="/about">ABOUT</Link></li>
            <li><Link to="/blog">BLOG</Link></li>
            <li><Link to="/contact">CONTACT</Link></li>
          </ul>
          <div className="Merch-download-signinb3">
            <Link to="/company">
              <img
                src={`${process.env.PUBLIC_URL}/images/Download_buttonh.png`}
                alt="Download Button"
                className="Downloadb3"
              />
            </Link>
            <Link to="/company" className="Signinb3">SIGN IN</Link>
            <div className="Merch-iconb3">
              <FontAwesomeIcon icon={faCartShopping} />
            </div>
          </div>
        </nav>
      </header>

      <div className="App-content-section1-entry3">
        <div className="Section1-entry3-background">
          <img
            src={`${process.env.PUBLIC_URL}/images/blogentry3_pic.png`}
            alt="Blog Background"
            className="entry3-image"
          />
        </div>
        <div className="Section1-entry3-content">
          <h1 className="Page-entry3-title">RECENT BLOG</h1>
          <div className="entry3-title-holder">
            <h2 className="entry3-feature-title">CLIENT FIRST | </h2>
            <h3 className="entry3-feature-title2">BUT WHAT<br />DOES THAT REALLY MEAN?</h3>
          </div>
          {/* Profile Picture and Author Info */}
          <div className="entry3-author-info">
            <img
              src={`${process.env.PUBLIC_URL}/images/blogauthor_pic.png`}
              alt="Grant Jaffarian"
              className="entry3-author-image"
            />
            <p className="entry3-author-text">Grant Jaffarian & Ken Sunzeri | November 2024</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog3;
